import { DataModel } from './DataModel';
import { Answer, Condition, ConInformative } from './AnswerModel';
import { Question } from './QuestionModel';
import { ConAnswer } from './ConAnswerModel';
import { FileModel } from './FileModel';
import { Informative } from './InformativeModel';
import { Company } from './CompanyModel';
import { SimpleQuestion } from './SimpleQuestionModel';
import { SimpleAnswer } from './SimpleAnswerModel';

export const defaultValues = {
    file:{
        PartitionKey: '',
        RowKey: '',
        CompanyId: '',
        ProductType: '',
        DateCreated: '',
        DateModified: '',
        CreatedBy: '',
        ModifiedBy: '',
        Notes: '',
        Data: null
    } as FileModel,
    baseData: {
        CompanyCode: '',
        ProductType: '',
        Informatives: [],
        Companies: [],
        Answers: [],
        Questions: []
    } as DataModel,
    conAnswer: {
        QuestionId: '',
        AnswersSet: [],
        Informatives: []
    } as ConAnswer,
    answer: {
        Answer: "",
        Type: "default",
        Conditions: [],
        Summary: ""
    } as Answer,
    simpleAnswer: {
        Label: "",
        Answer: "",
        Summary: "",
        Informatives: []
    } as SimpleAnswer,
    conInformative: {
        InformativeId: [],
        Conditions: [],
        Type: "default"
    } as ConInformative,
    question: {
        Id: '',
        Section: '',
        Question: '',
        Product: ''
    } as Question,
    simpleQuestion: {
        Id: '',
        Section: '',
        Question: '',
        Product: '',
        Answers: []
    } as SimpleQuestion,
    condition: {
        Key: '',
        Value: ''
    } as Condition,
    company: {
        Id: '',
        Name: '',
        Address: '',
    } as Company,
    informative: {
        Id: '',
        Content: ''
    } as Informative,
};