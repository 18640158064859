import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import CopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useEndpoints } from '../utils/EndpointContext';
import { FileModel } from '../models/FileModel';
import Moment from 'react-moment';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { defaultValues } from '../models/defaultValues';

type Props = {}

type Params = {
    companyCode: string;
    productType: string;
};

const DataVersionsPage: React.FC = (props: Props) => {

    const { companyCode, productType } = useParams<Params>();
    const { accounts } = useMsal();

    const endPoints = useEndpoints();
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [savedData, setSavedData] = useState<FileModel[] | null>();

    const [openPublishModal, setOpenPublishModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<FileModel | null>(null);

    const handleEdit = (item : FileModel) => {
        window.location.href = `/${companyCode}/dataversions/${productType}/data/${item.RowKey}`;
    }
    
    const handleDelete = async (item : FileModel) => {
        await axios.delete(endPoints.questionEditorDelete!, {
            params: {
                partitionKey: item.PartitionKey,
                rowKey: item.RowKey
            }
        }).then(() => {
            window.location.reload();
        });
    }

    const handleDownload = async (item : FileModel) => {
        axios.get(endPoints.questionEditorRead!, {
            params: {
                BlobName: `${item.PartitionKey}/${item.RowKey}.json`
            }
        }).then((response) => {
            const blob = new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = item.PartitionKey;
            a.click();
            URL.revokeObjectURL(url);
        });
    }

    const handleCopy = async (item : FileModel) => {
        axios.get(endPoints.questionEditorRead,
            {
                params: {
                    partitionKey: companyCode,
                    rowKey: item.RowKey,
                    downloadData: true
                }
            }
        ).then((response) => {
            item.Data = response.data.Data;
            item.DateCreated = new Date().toISOString();
            item.CreatedBy = accounts[0]?.name!;
            item.DateModified = null;

            axios.post(endPoints.questionEditorCreate!, item).then(() => {
                window.location.reload();
            });
        });
    }

    const handleCreate = async () => {

        const newfile = defaultValues.file;
        newfile.PartitionKey = companyCode!;
        newfile.ProductType = productType!;
        newfile.DateCreated = new Date().toISOString();
        newfile.DateModified = new Date().toISOString();
        newfile.CreatedBy = accounts[0]?.name!;

        let data = defaultValues.baseData;
        data.CompanyCode = companyCode!;
        data.ProductType = productType!;

        newfile.Data = data;

        axios.post(endPoints.questionEditorCreate!, newfile).then(() => {
            window.location.reload();
        });
    }

    const handleOpenPublishModal = (item : FileModel) => {
        setOpenPublishModal(true);
        setSelectedItem(item);
    }

    const handleClosePublishModal = () => {
        setSelectedItem(null);
        setOpenPublishModal(false);
    }

    const handlePublish = async (item : FileModel) => {
        item.IsPublished = true;

        axios.post(endPoints.questionEditorUpdate!, item).then(() => {
            window.location.reload();
        });

        handleClosePublishModal();
    }

    const renderSavedData = () => {
        return savedData?.map((item, index) => {
            return <Paper key={index} style={{marginBottom: '1em'}}>
                    <Stack spacing={1} p={2} direction="row" justifyContent={"space-between"}>
                        <Stack spacing={1} p={1} width={'90%'}>
                            <Grid container spacing={2} justifyContent={"space-between"}>
                                <Grid item xs={3}><small style={{'fontWeight': 600}}>Id</small><br />{item.RowKey}<Divider /></Grid>
                                <Grid item xs={1}><small style={{'fontWeight': 600}}>Product Type</small><br />{item.ProductType}<Divider /></Grid>
                                <Grid item xs={2}><small style={{'fontWeight': 600}}>Date Created</small><br /><Moment format="DD/MM/YYYY HH:mm">{item.DateCreated}</Moment><Divider /></Grid>
                                <Grid item xs={2}><small style={{'fontWeight': 600}}>Date Modified</small><br /><Moment format="DD/MM/YYYY HH:mm">{item.DateModified!}</Moment><Divider /></Grid>
                                <Grid item xs={2}><small style={{'fontWeight': 600}}>Created By</small><br />{item.CreatedBy}<Divider /></Grid>
                                <Grid item xs={2}><small style={{'fontWeight': 600}}>Modified By</small><br />{item.ModifiedBy}<Divider /></Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><small style={{'fontWeight': 600}}>Notes</small><br />{item.Notes!}</Grid>
                            </Grid>
                        </Stack>
                        <Stack spacing={1} className="tools" direction="column" justifyContent={"center"}>
                            {item.DatePublished ? <></> : <Button variant="contained" color='primary' onClick={() => handleEdit(item)}>Edit <EditIcon /></Button> }
                            <Button variant="contained" color='secondary' onClick={() => handleCopy(item)}>Copy <CopyIcon /></Button>
                            <Button variant="contained" color='secondary' onClick={() => handleDownload(item)}>Export <DownloadIcon /></Button>
                            {item.DatePublished ? <></> : <Button variant="contained" color='info' onClick={() => handleOpenPublishModal(item)}>Publish <PublishIcon /></Button> }
                            {item.DatePublished ? <></> : <Button variant="contained" color='error' disabled={true} onClick={() => handleDelete(item)}>Delete <DeleteIcon /></Button> }
                        </Stack>
                    </Stack>
                </Paper>
        });
    };

    useEffect(() => {

        if(hasLoaded){ return; }

        axios.get(`${endPoints.questionEditorList!}`, {
            params: {
                companyCode: companyCode,
                productType: productType
            }
        }).then((response) => {
            setSavedData(response.data);
        });

        setHasLoaded(true);

    }, []);


    return(
        <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
                <Stack>
                    {savedData ? renderSavedData() : <>No saved data</> }
                    {savedData ? <>
                        <Dialog
                            open={openPublishModal}
                            onClose={handleClosePublishModal}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                                <DialogTitle>Confirm Publish</DialogTitle>
                                <DialogContent>
                                    <p id="modal-description">Are you sure you want to publish this item?<br />Once published, it cannot be edited.</p>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="contained" color='error' onClick={() => handlePublish(selectedItem!)}>Confirm</Button>
                                    <Button variant="contained" color='secondary' autoFocus onClick={handleClosePublishModal}>Cancel</Button>
                                </DialogActions>
                        </Dialog>
                    </> : <></>}
                    <Button variant="contained" color='secondary' onClick={() => handleCreate()}>Create New <CreateIcon /></Button>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default DataVersionsPage;