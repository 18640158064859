import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { themeOptions } from './geodesysTheme'

import { EventType, AuthenticationResult  } from "@azure/msal-browser";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EndpointsProvider } from './utils/EndpointContext';
import { loadEndpoints } from './data/Endpoints';
import { createMsalInstance } from './authConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme(themeOptions);

loadEndpoints().then(endPoints => {
  const msalInstance = createMsalInstance(endPoints);
  
  const accounts = msalInstance.getAllAccounts();
  
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE){
        
      }else if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);

      }else if(event.eventType === EventType.HANDLE_REDIRECT_START){
      }
  });

  root.render(
      <BrowserRouter>
        <EndpointsProvider>
          <ThemeProvider theme={theme}>
            {msalInstance ? <App pca={msalInstance} /> : <>Loading</> }
          </ThemeProvider>
        </EndpointsProvider>
      </BrowserRouter>
  );
}).catch(error => {
  console.error("Failed to load configuration:", error);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
