import { Alert, Box, Breadcrumbs, Divider, Grid, Link, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { ConAnswer } from '../models/ConAnswerModel';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import { DataModel } from '../models/DataModel';
import { useNavigate, useParams } from 'react-router-dom';
import { Answer, ConInformative } from '../models/AnswerModel';
import ReactHtmlParser from 'react-html-parser'
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';

type Props = {}
type QuestionParams = {
    qid: string;
};

const ConAnswerPage: React.FC = (props: Props) => {
    const endPoints = useEndpoints();
    const navigate = useNavigate();

    let { qid } = useParams<QuestionParams>();


    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [baseData, setBaseData] = useState<DataModel>(defaultValues.baseData);
    const [data, setData] = useState<ConAnswer>(defaultValues.conAnswer);
    const [questionData, setQuestionData] = useState<Question>(defaultValues.question);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
        .then(response => {
            setBaseData(response.data);
            let conAnswer = response.data.Answers.find((element : ConAnswer) => element.QuestionId === qid);
            let conQuestion = response.data.Questions.find((element : Question) => element.Id === qid);
            setQuestionIndex(response.data.Answers.indexOf(conAnswer));
            setQuestionData(conQuestion);
            setData(conAnswer);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
      }, [itemAdded]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData.Answers[questionIndex] = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
        setOpenAlert(true);
    };

    const handleAnswerSetAdd = () => {
        const newData = {...data};
        newData.AnswersSet = [...newData.AnswersSet, {Answers: []}];
        setItemAdded(true);
        setData(newData);
        handleSave();
    };

    const handleInformativeAdd = () => {
        const newData = {...data};
        setItemAdded(true);
        setData(newData);
        handleSave();
    };

    const handleAnswerAdd = (answerSetIndex: number) => {
        const newData = {...data};
        newData.AnswersSet[answerSetIndex].Answers = [...newData.AnswersSet[answerSetIndex].Answers, defaultValues.answer];
        setItemAdded(true);
        setData(newData);
        handleSave();
    };

    const handleAnswerSetRemove = (index: number) => {
        const newData = {...data};
        newData.AnswersSet.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const handleInformativeRemove = (index: number) => {
        const newData = {...data};
        newData.Informatives.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const handleAnswerEdit = (answerSetIndex: number, index: number) => {
        navigate(`/questions/${data.QuestionId}/answers/${answerSetIndex}/${index}`);
    };

    const handleInformativeEdit = (index: number) => {
        navigate(`/questions/${data.QuestionId}/answers/informative/${index}`);
    };

    const handleAnswerRemove = (answerSetIndex: number, index: number) => {
        const newData = {...data};
        newData.AnswersSet[answerSetIndex].Answers.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const renderConditions = (answer: Answer | ConInformative) => {
        if(answer.Type == "notand"){
            return (<>{answer.Conditions[0].Key} not equal to "{answer.Conditions[0].Value}"</>);
        }else if(answer.Type == "and"){
            return answer.Conditions.map((condition) => {
                return <>{condition.Key} equal to "{condition.Value}" <strong>AND</strong> </>
            });
        }else if(answer.Type == "or"){
            return answer.Conditions.map((condition) => {
                return <>{condition.Key} equal to "{condition.Value}" <strong>OR</strong> </>
            });
        }else if(answer.Type == "nested"){
            return answer.Conditions.map((condition) => {
                return <><strong>NESTED</strong> {condition.Key} equal to "{condition.Value}" <strong>AND</strong> </>
            });
        }

        return (<></>);
    }

    const renderInformative = (informativeId: string) => {
        return baseData.Informatives.map((informative) => {
            if(informative.Id === informativeId){
                return <><small><strong>{informative.Id}</strong></small> | {informative.Content}</>;
            }

            return (<></>);
        });
    }

    return(
        <div>
            {data ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link underline="hover" onClick={() => { navigate(`/questions`) }}>Questions</Link>
                            <Typography color="text.primary">Question {data.QuestionId}</Typography>
                            <Typography color="text.primary">Answers</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question<SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    <Typography>Question</Typography>
                    <Paper>
                        {questionData.Question}
                    </Paper>
                    <Typography>Answer Sets</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            {data.AnswersSet.map((answerSet, answerSetIndex) => (
                                <Grid container spacing={2} key={answerSetIndex}>
                                    <Grid item xs={11}>
                                        <Typography>Answer Set {answerSetIndex + 1}</Typography>
                                        {answerSet.Answers.map((answer, answerIndex) => (
                                            <Stack key={answerIndex}>
                                                <Box sx={{ m: 2 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={10}>
                                                            <Box><strong>Summary:</strong> {answer.Summary}</Box>
                                                            <Box><strong>Full Answer: </strong> {ReactHtmlParser(answer.Answer)}</Box>
                                                            <Box><small><strong>Conditions:</strong> {renderConditions(answer)}</small></Box>
                                                        </Grid>
                                                        <Grid item xs={2} display="flex" justifyContent="flex-end" >
                                                            <Button color='error' onClick={() => handleAnswerRemove(answerSetIndex, answerIndex)}> <DeleteIcon /></Button>
                                                            <Button variant="contained" color='secondary' onClick={() => handleAnswerEdit(answerSetIndex, answerIndex)}><EditIcon /></Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Divider variant="middle" />
                                            </Stack>
                                        ))}
                                        <Stack>
                                            <Box sx={{ m: 2 }} display="flex" justifyContent="flex-end" className="tools">
                                                <Button variant='contained' color='primary' onClick={() => handleAnswerAdd(answerSetIndex)}>Add Answer <CreateIcon /></Button>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1} className='actions-bar'>
                                        <Button color='error' onClick={() => handleAnswerSetRemove(answerSetIndex)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color='secondary'  onClick={() => handleAnswerSetAdd()}>Add New Set <CreateIcon /></Button>
                        </Box>
                    </Paper>
                    <Typography>Informatives</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            {data.Informatives.map((informative, informativeIndex) => (
                                <Stack key={informativeIndex}>
                                    <Box sx={{ m: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                {informative.InformativeId.map((id) => (<Box sx={{ m: 2 }}><Typography>{renderInformative(id)}</Typography></Box>))}
                                                <Box sx={{ m: 2 }}><small><strong>Conditions:</strong> {renderConditions(informative)}</small></Box>
                                            </Grid>
                                            <Grid item xs={2} display="flex" justifyContent="flex-end" >
                                                <Button color='error' onClick={() => handleInformativeRemove(informativeIndex)}><DeleteIcon /></Button>
                                                <Button variant="contained" color='secondary' onClick={() => handleInformativeEdit(informativeIndex)}><EditIcon /></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider variant="middle" />
                                </Stack>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant='contained' color='secondary' onClick={() => handleInformativeAdd()}>Add Informative <CreateIcon /></Button>
                        </Box>
                    </Paper>
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question updated successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Question</>}
        </div>
    );
}

export default ConAnswerPage;